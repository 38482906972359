/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/gsap@2.1.3/src/minified/TimelineMax.min.js
 * - /npm/gsap@2.1.3/src/minified/TweenMax.min.js
 * - /npm/gsap@2.1.3/src/minified/easing/EasePack.min.js
 * - /npm/gsap@2.1.3/src/minified/plugins/ScrollToPlugin.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
